import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{ width: "100%", height: "100%"}}>
      <h1>NOT FOUND!</h1>
      <p>There's no such page ... yet!?</p>
      <p>SORRY!</p>
    </div>
  </Layout>
);

export default NotFoundPage;
